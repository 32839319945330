import { render, staticRenderFns } from "./FormWrapper.vue?vue&type=template&id=422e0860&scoped=true"
import script from "./FormWrapper.vue?vue&type=script&lang=js"
export * from "./FormWrapper.vue?vue&type=script&lang=js"
import style0 from "./FormWrapper.vue?vue&type=style&index=0&id=422e0860&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422e0860",
  null
  
)

export default component.exports