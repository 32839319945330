<template>
  <form-wrapper ref="form" reference="generalForm" boxed :form-title="$t('producer.titles.generalInformation')" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="12" class="ma-0 py-2">
        <!-- Filtros de Ubicación -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t("producer.fields.locations")}}</label>
          <ShowMoreComponent v-if="item.storageDevices.length > 0" :item="item" secondary-field="storageDevices.name" />
          <span v-else>-</span>
        </div>
        <LocationFilters v-else
                         :two-columns="true"
                         :storage-label="$t('storage.label')"
                         :location-label="$t('locations.label')"
                         storage-ref="storageRef"
                         location-ref="locationRef"
                         :locations-selected="locations"
                         :storages-selected="item.storageDevices"
                         @update:locations:origin="locations = $event"
                         @update:storages:origin="item.storageDevices = $event"
                         @on:valid-form="$emit('update:valid', $event)"
                         :show-origin="true"
                         :show-destination="true"
                         :autocomplete-props="{
                          color: 'secondary',
                          itemColor: 'secondary',
                          smallChips: true,
                          clearable: true,
                          multiple: true,
                          dense: true,
                          outlined: true
                        }"
        />
      </v-col>
      <v-col cols="12" class="ma-0 py-2">
        <!-- Área de texto para observaciones -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.observations')}}</label>
          <span class="field-value">{{ item.observations || '-' }}</span>
        </div>
        <v-textarea v-else
                    v-model="item.observations"
                    :label="$t('producer.fields.observations')"
                    outlined
                    auto-grow
                    dense
                    color="primary"
                    v-on:keyup.86="replace"
                    v-on:keyup.ctrl.86="replace"
                    :disabled="!isEditable"
        ></v-textarea>
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
import FormWrapper from "@/components/common/FormWrapper.vue";
import {mapGetters} from "vuex";
import LocationFilters from "@/components/filters/LocationFilters.vue";
import ShowMoreComponent from "@/components/ShowMoreComponent.vue";

export default {
  components: {ShowMoreComponent, LocationFilters, FormWrapper },
  props: {
    item: {
      type: Object,
      require: true,
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    valid: true,
    locations: [],
    storages: []
  }),
  created() {
    this.source = 'generalSection';
  },
  mounted() {
    if (this.item.id) {
      if (Array.isArray(this.item.storageDevices)) {
        // Usar Set para obtener locations únicas
        const uniqueLocationIds = [...new Set(
          this.item.storageDevices.map(device => device.primaryLocation.primaryLoationId)
        )];
        
        this.locations = uniqueLocationIds;

        this.$root.$emit('set:locations:filters', {
          locations: uniqueLocationIds,
          storageDevice: _.map(this.item.storageDevices, 'storageDeviceId')
        });
      }
    } else {
      this.$root.$emit('set:locations:filters', {
        locations: [],
        storageDevice: []
      });
    }
  },
  computed: {
    ...mapGetters({
      requireRules: "general/requireRules"
    }),
    source: {
      get() {
        return this.$store.getters['ui/GET_FILTER_SOURCE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTER_SOURCE', val)
      },
    },
  },
  methods: {
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
  beforeDestroy() {
    this.$refs.form.$refs.generalForm.reset();
    console.log("GeneralSection Destroyed");
  }
};
</script>

<style scoped>
.display-field {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: .2rem;
  display: flex;
  flex-direction: column;
}
.field-label {
  font-weight: bold;
  margin-right: 5px;
}
.field-value {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>

