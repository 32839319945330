<template>
  <form-wrapper ref="form" reference="mainForm" boxed :form-title="$t('producer.titles.mainInformation')" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="12" class="ma-0 py-2">
        <!-- Nombre con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.name')}}</label>
          <span class="field-value">{{ item.name }}</span>
        </div>
        <v-text-field v-else
                      v-model="item.name"
                      :label="$t('producer.fields.name')"
                      required
                      :rules="rules.name"
                      outlined
                      dense
                      color="primary"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable"
        >
          <template v-slot:label>
            {{$t('producer.fields.name')}} <strong class="red--text">*</strong>
          </template>
          <template v-slot:append>
            <span v-if="loading.name" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="ma-0 py-2">
        <!-- CPF con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.cpf')}}</label>
          <span class="field-value">{{ item.cpf || '-' }}</span>
        </div>
        <v-text-field v-else
                      class="mr-1"
                      v-model="item.cpf"
                      :label="$t('producer.fields.cpf')"
                      placeholder="___.___.___-__"
                      outlined
                      dense
                      color="primary"
                      v-mask="'###.###.###-##'"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :rules="rules.cpf"
                      :disabled="!isEditable">
          <template v-slot:append>
            <span v-if="loading.cpf" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="ma-0 py-2">
        <!-- RG con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.rg')}}</label>
          <span class="field-value">{{ item.rg || '-' }}</span>
        </div>
        <v-text-field v-else
                      class="ml-1"
                      v-model="item.rg"
                      :label="$t('producer.fields.rg')"
                      outlined
                      dense
                      color="primary"
                      @input="toUpperCase()"
                      :rules="rules.rg"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable">
          <template v-slot:append>
            <span v-if="loading.rg" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" class="ma-0 py-2">
        <!-- Nombre con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.inscriptionCode')}}</label>
          <span class="field-value">{{ item.inscriptionCode }}</span>
        </div>
        <v-text-field v-else
                      v-model="item.inscriptionCode"
                      :label="$t('producer.fields.inscriptionCode')"
                      :rules="lengthRules(max)"
                      :counter="max"
                      maxlength="max"
                      outlined
                      dense
                      color="primary"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable"
        >
          <template v-slot:append>
            <span v-if="loading.name" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
import FormWrapper from "@/components/common/FormWrapper.vue";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/plugins/i18n";
import { max } from "lodash";

export default {
  components: { FormWrapper },
  props: {
    item: {
      type: Object,
      require: true,
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    valid: true,
    max: 60,
    loading: {
      cpf: false,
      rg: false,
      name: false
    },
    ruleResults: {
      cpf: true,
      rg: true,
      name: true
    },
    validationTimers: {
      cpf: null,
      rg: null,
      name: null
    }
  }),
  watch: {
    'item.cpf': function(newVal) {
      this.triggerValidation('cpf', newVal);
    },
    'item.rg': function(newVal) {
      this.triggerValidation('rg', newVal);
    },
    'item.name': function(newVal) {
      this.triggerValidation('name', newVal);
    }
  },
  computed: {
    ...mapGetters({
      requireRules: "general/requireRules",
      lengthRules: "general/lengthRules",
      getRegexValidationRule: 'general/getRegexValidationRule'
    }),
    rules() {
      return {
        cpf: [
          ...this.getRegexValidationRule('cpf'),
          v => this.ruleResults.cpf === true ? true : this.ruleResults.cpf
        ],
        rg: [
          v => this.ruleResults.rg === true ? true : this.ruleResults.rg
        ],
        name: [
          ...this.requireRules,
          v => this.ruleResults.name === true ? true : this.ruleResults.name
        ]
      };
    }
  },
  methods: {
    ...mapActions({
      validateField: 'producer/validateField'
    }),
    triggerValidation(field, value) {
      // Clear previous timeout if exists
      if (this.validationTimers[field]) {
        clearTimeout(this.validationTimers[field]);
      }
      
      // Reset validation result immediately if the field is empty
      if (!value || value.trim() === '') {
        this.ruleResults[field] = true;
        this.loading[field] = false;
        return;
      }
      
      // Set new timeout
      this.validationTimers[field] = setTimeout(() => {
        // Only validate if there's a value
        if (value) {
          // Create validation object with all fields
          const validationData = {
            cpf: this.item.cpf || '',
            rg: this.item.rg || '',
            name: this.item.name || ''
          };
          
          if (this.item.id) {
            validationData.id = this.item.id;
          }
          
          this.asyncFieldsValidation(validationData);
        }
      }, 500);
    },
    asyncFieldsValidation: async function(fieldsToValidate) {
      // Set loading state for fields that are being validated
      Object.keys(fieldsToValidate).forEach(field => {
        if (fieldsToValidate[field]) {
          this.loading[field] = true;
        }
      });
      
      try {
        // Enviar todos los campos juntos para validación
        const response = await this.validateField(fieldsToValidate);
        
        // Procesar respuesta para cada campo - asegurar que los mensajes de error se muestran correctamente
        if (fieldsToValidate.cpf) {
          this.loading.cpf = false;
          // Explícitamente manejar false como un error
          this.ruleResults.cpf = response.cpf === true ? true : i18n.t('common.rules.cpfExist');
        }
        
        if (fieldsToValidate.rg) {
          this.loading.rg = false;
          // Explícitamente manejar false como un error
          this.ruleResults.rg = response.rg === true ? true : i18n.t('common.rules.rgExist');
        }
        
        if (fieldsToValidate.name) {
          this.loading.name = false;
          // Explícitamente manejar false como un error
          this.ruleResults.name = response.name === true ? true : i18n.t('common.rules.nameExist');
        }
        
        // No force validation of all fields - only update the current field state
        // We'll just check if overall form is valid without displaying all errors
        this.$nextTick(() => {
          // Check form validity without showing all validation errors
          const isFormValid = this.$refs.form && 
                              this.$refs.form.$refs.mainForm && 
                              this.$refs.form.$refs.mainForm.validate(true); // validate without showing errors
          
          // Emit valid state based on our field validations and form's internal state
          const fieldsValid = response.cpf === true && response.rg === true && response.name === true;
          this.$emit('update:valid', fieldsValid && isFormValid);
        });
      } catch (error) {
        console.error("Validation error:", error);
        // Reset loading states on error
        Object.keys(this.loading).forEach(key => {
          this.loading[key] = false;
        });
      }
    },
    toUpperCase() {
      if (this.item.rg)
        this.item.rg = this.item.rg.toUpperCase();
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
  beforeDestroy() {
    // Clear any pending validation timers
    Object.values(this.validationTimers).forEach(timer => {
      if (timer) clearTimeout(timer);
    });
    
    if (this.$refs.form && this.$refs.form.$refs.mainForm) {
      this.$refs.form.$refs.mainForm.reset();
    }
    console.log("MainSection Destroyed");
  }
};
</script>

<style scoped>
.display-field {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: .2rem;
}
.field-label {
  font-weight: bold;
  margin-right: 5px;
}
.field-value {
  font-size: 16px;
}
</style>
